<template>
  <div class="p-grid crud-demo">
    <Loader :message="loadingMessage" v-model="loading" />
    <Dialog v-model:visible="modalProveedor" :closable="true" :style="{ width: '75vw' }" :modal="true">
        <SupplierUI @update="supplierUpdated" @save="supplierSaved" :id="id_supplier" :invoice_issuing_entity="invoice_issuing_entity" />
    </Dialog>
    <Dialog v-model:visible="modalSupplier" :closable="true" :style="{ width: '75vw' }" :modal="true">
      <InvoiceUI :id="invoiceId" />
    </Dialog>
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <h2>Buzon de Facturas</h2>
        <BasicFormToolbar
        @new="refresh" @extraer="extraer" @list="list"
        :actions="['new','extraer', 'list']" />
        <div class="grid">
          <div class="col-12">
            <Fieldset legend="Parametros">
              <div class="grid formgrid p-fluid">
                  <FormCalendar :wrapperClass="'col-3 field'"  label="Fecha Inicio" v-model="entity.fecha_inicio" />
                  <FormCalendar :wrapperClass="'col-3 field'"  label="Fecha Fin" v-model="entity.fecha_fin"/>
                  <FormInputText :wrapperClass="'col-3 field'" label="RFC" v-model="entity.rfc"/>
                  <FormDropdown :wrapperClass="'col-3 field'" label="Tipo" v-model="entity.tipo"
                   :options="types" :optionLabel="'text'" :optionValue="'id'" />
              </div>
            </Fieldset>
          </div>
           <div class="col-12">
            <Fieldset legend="Acciones">
              <div class="grid formgrid p-fluid">
                  <div class="col-3">
                    <ImageButton @click="downloadZip" :text="'Descargar ZIP'" :icon="'zip'" :width="'1.5rem'" />
                  </div>
                  <div class="col-3">
                    <ImageButton @click="estatusSATList" :text="'Validar Estatus SAT'" :icon="'sat'" :pack="'invoicing'" :width="'1.5rem'" />
                  </div>
                  <div class="col-3">
                    <ImageButton @click="importDTP" :text="'Importar CXP'" :icon="'cxp'" :pack="'invoicing'" :width="'1.5rem'" />
                  </div>
              </div>
            </Fieldset>
          </div>
          <div class="col-12 scrollable-table" style="overflow-x: scroll;">
            <BasicDatatable 
            @selected-change="getSelection" @pdf="downloadPDF" @xml="downloadXML" :rowaction="true" :files="['xml','pdf']" :rows="entities"
            @on-click-component="goCXP"
            selectionModeColumn="multiple" :headers="headers" @proveedor="openProveedor" @estatusSAT="estatusSAT" :contextMenu="true" 
            dataKey="id"
            :menuModel="[
              {label: 'Proveedor', icon: 'pi pi-fw pi-user', action: 'proveedor'},
              {label: 'Verificar esatus SAT', icon: 'pi pi-fw pi-globe', action: 'estatusSAT'}
            ]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ErrorToast, HeaderGrid, Toast } from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import Session from "../../../mixins/sessionMixin";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormDropdown from "../../../components/general/FormDropdown.vue";
import ImageButton from "../../../components/general/ImageButton.vue";
import { DTPInvoice } from "../../../models/cxp/DTPInvoice";
import SupplierUI from "../Catalogos/Supplier.vue";
import InvoiceUI from "../Aplicaciones/Invoice.vue";
export default {
  mixins: [Session],
  data() {
    return {
      selected: [],
      entity: {
        fecha_inicio: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        fecha_fin: new Date(),
        rfc: null,
        tipo: ""
      },
      types: [
        {id: "", text: "TODOS"},
        {id: "I", text: "INGRESO"},
        {id: "E", text: "EGRESO"},
      ],
      entities: [],
      headers: [
          new HeaderGrid('Factura', 'concat_invoice', {formula: 'concat', expression: 'series + {-} + id_invoice'}),
          new HeaderGrid('RFC','issuing_rfc'),
          new HeaderGrid('Estatus','status_name', {formula: 'color', expression: 'is_sat_valid ? "green" : "red";'}),
          new HeaderGrid('Tipo','voucher_type'),
          new HeaderGrid('Cliente','issuing_name'),
          new HeaderGrid('Fecha','date', { type: "date" }),
          new HeaderGrid('SubTotal','subtotal', { type: "currency", function: "SUM" }),
          new HeaderGrid('Impuestos Tr.','taxes_total_transferred', { type: "currency", function: "SUM" }),
          new HeaderGrid('Impuestos Ret.','taxes_total_detained', { type: "currency", function: "SUM" }),
          new HeaderGrid('Total','total', { type: "currency", function: "SUM" }),
          new HeaderGrid('CXP','is_debt_to_pay', { type: 'component', filter: false, component: "Button", componentProperties: { label: "Ver CXP" } })
      ],
      loading: false,
      extrayendo: false,
      modalProveedor: false,
      modalSupplier: false,
      id_supplier: null,
      id_editing: null,
      invoice_issuing_entity: null,
      invoiceId: null
    };
  },
  computed: {
    loadingMessage() {
      return this.extrayendo ? "Extrayendo correos de buzon" : "Cargando";
    }
  },
  methods: {
    goCXP(payload){
      // console.log(payload);
      this.invoiceId = payload.data.id;
      this.modalSupplier = true;
    },
    getSelection(selection) {
      this.selected = selection;
    },
    refresh() {
      this.entity = {
        fecha_inicio: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        fecha_fin: new Date(),
        rfc: null,
        tipo: ""
      };
      this.selected = [];
      this.entities = [];
    },
    supplierSaved(supplier) {
      this.modalProveedor = false;
      this.entities.forEach(x => {
        x.id_supplier = 
          x.issuing_rfc == supplier.rfc ? supplier.id : x.id_supplier;
      });
      this.id_editing = null;
      this.id_supplier = null;
    },
    supplierUpdated() {
      this.modalProveedor = false;
      this.id_editing = null;
      this.id_supplier = null;
    },
    async estatusSAT(payload) {
      try {
        this.loading = true;
        let dtp_invoice = new DTPInvoice(this.session);
        dtp_invoice.id = payload.id;
        let response = await dtp_invoice.validaSat();
        this.entities.filter(x => x.id == payload.id).is_sat_valid = response.vigente;
        this.$toast.add(
          new Toast({
            summary: "Validacion SAT",
            detail: "Factura en estatus " + response.estado,
          })
        );
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally { 
        this.loading = false;
      }
    },
    async importDTP() {
      try {
        if  (this.selected.length == 0)
          throw "ERROR. No se ha seleccionado ninguna factura";
        this.loading = true;
        await new DTPInvoice(this.session).importDTP(this.selected);
        this.$toast.add(
          new Toast({
            summary: "Validacion SAT",
            detail: "Facturas validadas",
          })
        );
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally { 
        this.loading = false;
      }
    },
    async estatusSATList() {
      try {
        if  (this.selected.length == 0)
          throw "ERROR. No se ha seleccionado ninguna factura";
        this.loading = true;
        this.selected = await new DTPInvoice(this.session).validaSatList(this.selected);
        this.$toast.add(
          new Toast({
            summary: "Validacion SAT",
            detail: "Facturas validadas",
          })
        );
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally { 
        this.loading = false;
      }
    },
    openProveedor(payload) {
      this.id_supplier = payload.id_supplier ?? 0;
      this.id_editing = payload.id;
      this.invoice_issuing_entity = {
        name: payload.issuing_name,
        rfc: payload.issuing_rfc,
        email: payload.invoice_email
      }
      console.log(this.id_supplier, 'id_supplier');
      console.log(this.invoice_issuing_entity, 'invoice_issuing_entity');

      this.modalProveedor = true;
    },
    downloadXML(payload) {
      window.open(this.$config.file_server_invoice_xml + payload.xml_filename, '_blank');
    },
    downloadPDF(payload) {
      window.open(this.$config.file_server_invoice_pdf + payload.pdf_filename, '_blank');
    },
    async downloadZip() {
      try {
        if  (this.selected.length == 0)
          throw "ERROR. No se ha seleccionado ninguna factura";
        this.loading = true;
        await new DTPInvoice(this.session).zip(this.selected);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally{ 
        this.loading = false;
      }
    },
    async extraer() {
      this.loading = true;
      this.extrayendo = true;
      try {
        await new DTPInvoice(this.session).import();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
        this.extrayendo = false;
      }
    },
    async list() {
      this.loading = true;
      try {
        this.selected = [];
        this.entities = await new DTPInvoice(this.session).list(this.entity);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    }
  },
  components: { InvoiceUI, ImageButton,FormDropdown, SupplierUI, FormInputText, FormCalendar,Loader,BasicFormToolbar, BasicDatatable},
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

.scrollable-table::-webkit-scrollbar {
	width: 5px;
    height: 8px;
	}

	/* Track */
.scrollable-table::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 50px;
	}

	/* Handle */
.scrollable-table::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 50px;
	}

	/* Handle on hover */
.scrollable-table::-webkit-scrollbar-thumb:hover {
	background: #555;
	box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2)
	}
</style>
